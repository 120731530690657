import React, { useEffect, useState } from "react";
import styles from "./flight-route.module.scss";
import MultiCityAutosuggest from "../../multi-city/multi-city-autosuggest";
import { AirportData } from "../../../../models/models";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDate,
  getLocalStorageData,
  setLocalStorageData,
  updateRecentSearchFlightStatus,
} from "../../../../../../utils/helper";
import { RootState } from "../../../../slice/RootReducer";
import { useTranslation } from "react-i18next";
import TGFloatingSelect from "../../../../../../shared/tg-select";
import moment from "moment";
import TGButton from "../../../../../../shared/tg-button";
import {
  flightStatusByRouteRequest,
  flightStatusRouteClear,
  flightStatusRouteInputSave,
} from "../../../../slice/flightRouteStatusSlice";
import { useNavigate } from "react-router";
import { saveAccessedActiveTab } from "../../../../slice/bookingWidgetSlice";

interface Props {
  type?: string;
  handleCloseSidebar?: any;
}

const FlightRoute: React.FC<Props> = (props: Props) => {
  const [recentAirports, setRecentAirports] = useState({
    from: [],
    to: [],
  });
  const [value, setValue] = useState({
    from: {},
    to: {},
    date: moment(new Date()).format("ddd, DD MMM YYYY"),
  });

  const [focused, setFocused] = useState({
    fromFocused:
      typeof window !== "undefined" &&
      !window?.location?.pathname?.includes("flightstatusroutedetail")
        ? true
        : false,
    toFocused: false,
    dateFocused: false,
  });

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const airportData: AirportData | any = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );
  const flightRouteDetailSelectedData = useSelector(
    (state: RootState) =>
      state?.osciReducer?.flightStatusRoute.flightRouteInputs
  );

  const flightStatusSelectedData = useSelector(
    (state: RootState) =>
      state?.osciReducer?.flightStatus.selectedRecentSearchData
  );
  const tripData = getLocalStorageData("tripStatus");
  const savedTripData = JSON.parse(tripData) || { from: [], to: [] };
  const recentFromData = savedTripData?.from;
  const recentToData = savedTripData?.to;

  useEffect(() => {
    let fromData = [];
    let toData = [];
    if (recentFromData && recentFromData?.length > 0) {
      fromData = recentFromData;
    }
    if (recentToData && recentToData?.length > 0) {
      toData = recentToData;
    }

    setRecentAirports({
      from: fromData,
      to: toData,
    });
  }, [recentFromData?.length, recentToData?.length]);

  useEffect(() => {
    if (flightRouteDetailSelectedData !== null) {
      setValue(flightRouteDetailSelectedData);
    }
  }, [flightRouteDetailSelectedData]);

  useEffect(() => {
    if (
      flightStatusSelectedData !== null &&
      flightStatusSelectedData.type === "Route"
    ) {
      let updatedValue = {
        from: flightStatusSelectedData.from,
        to: flightStatusSelectedData.to,
        date: flightStatusSelectedData.date,
      };
      setValue(updatedValue);
    }
  }, [flightStatusSelectedData]);

  const createDateArray = (startDate: any, range: number) => {
    const dates = [];
    for (let i = -range; i <= range; i++) {
      const newDate = moment(startDate).add(i, "days");
      dates.push({
        id: i + range + 1,
        listName: formatDate(newDate, true),
        value: formatDate(newDate, false),
      });
    }
    return dates;
  };
  const startDate = moment();
  const dateArray = createDateArray(startDate, 2);

  const handleFieldChange = (x: any, name: string) => {
    let temp = { ...value, [name]: x };
    if (name === "from") {
      if (x?.airportCode === value?.to?.airportCode) {
        temp = {
          from: x,
          to: {},
        };
      }
    } else if (name === "to") {
      if (x?.airportCode === value?.from?.airportCode) {
        temp = {
          from: {},
          to: x,
        };
      }
    }
    setValue(temp);
    if (name === "from") {
      setFocused({ ...focused, fromFocused: false, toFocused: true });
    } else if (name === "to") {
      if (x?.airportCode === value?.from?.airportCode) {
        setFocused({ ...focused, toFocused: false, fromFocused: true });
      } else {
        setFocused({ ...focused, toFocused: false, dateFocused: true });
      }
    } else if (name === "date") {
      setFocused({ fromFocused: false, toFocused: false, dateFocused: false });
    }
  };

  const handleSearch = () => {
    let tempData = {
      from: [...savedTripData?.from],
      to: [...savedTripData?.to],
    };
    const fromIndex =
      savedTripData?.from &&
      savedTripData?.from?.findIndex(
        (y: any) => y.airportCode === value?.from?.airportCode
      );
    const toIndex =
      savedTripData?.to &&
      savedTripData?.to?.findIndex(
        (y: any) => y.airportCode === value?.to?.airportCode
      );
    if (fromIndex < 0) {
      if (tempData?.from.length === 10) {
        tempData?.from?.pop();
      }
      tempData = {
        from: [value.from, ...tempData?.from],
        to: [...tempData?.to],
      };
    }
    if (toIndex < 0) {
      if (tempData?.to.length === 10) {
        tempData?.to?.pop();
      }
      tempData = {
        from: [...tempData?.from],
        to: [value.to, ...tempData?.to],
      };
    }
    setRecentAirports(tempData);
    setLocalStorageData("tripStatus", JSON.stringify(tempData));
    dispatch(flightStatusRouteClear());
    dispatch(
      flightStatusByRouteRequest({
        ...value,
        date: moment(value?.date).format("DDMMYY"),
      })
    );
    dispatch(flightStatusRouteInputSave(value));
    props?.handleCloseSidebar && props?.handleCloseSidebar(false);
    dispatch(saveAccessedActiveTab([3, "Route"]));
    navigate(`/${i18n.language}/flightstatus/flightstatusroutedetail`);
  };

  const handleClear = (name: string) => {
    let savedTrip = {
      from: [...savedTripData?.from],
      to: [...savedTripData?.to],
    };
    const newData = {
      ...savedTrip,
      [name]: [],
    };
    setRecentAirports(newData);
    setLocalStorageData("tripStatus", JSON.stringify(newData));
  };

  const handleSwap = () => {
    if (
      Object?.keys(value?.from)?.length > 0 &&
      Object?.keys(value?.to)?.length > 0
    ) {
      const tempToValue = value?.to;
      setValue({ ...value, from: tempToValue, to: value?.from });
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className={styles["flight-status-header"]}>
        {t("label_flightstatusbyroute_desc")}
      </div>
      <div className={`${styles["flight-status-field-wrapper"]} d-flex gap-3`}>
        <div
          className={`${styles["flight-status-routes-dropdown"]} d-flex w-50`}
        >
          <MultiCityAutosuggest
            label={t("label_book_widget_from")}
            customClassName="fromDropdownBorderRadius"
            onChange={() => {}}
            onSelect={handleFieldChange}
            searchPlaceHolder={t("label_multi_city_search_placeholder")}
            name="from"
            options={airportData}
            rowIndex={0}
            selectedPropsValue={value?.from}
            reverseArrow={true}
            onSwap={handleSwap}
            recentAirport={recentAirports?.from}
            onClearRecentSearch={() => handleClear("from")}
            isFocused={focused?.fromFocused}
            onClear={() => setValue({ ...value, from: {} })}
            onAutoSuggestBlur={() => {
              setFocused({
                ...focused,
                fromFocused: false,
                toFocused: false,
                dateFocused: false,
              });
            }}
          />
          <MultiCityAutosuggest
            label={t("label_book_widget_to")}
            customClassName="toDropdownBorderRadius"
            onChange={() => {}}
            onSelect={handleFieldChange}
            searchPlaceHolder={t("label_multi_city_search_placeholder")}
            name="to"
            options={airportData}
            rowIndex={0}
            selectedPropsValue={value?.to}
            reverseArrow={false}
            onSwap={() => {}}
            recentAirport={recentAirports?.to}
            onClearRecentSearch={() => handleClear("to")}
            isFocused={focused?.toFocused}
            onAutoSuggestBlur={() => {
              setFocused({
                ...focused,
                fromFocused: false,
                toFocused: false,
                dateFocused: false,
              });
            }}
            onClear={() => setValue({ ...value, to: {} })}
          />
        </div>
        <TGFloatingSelect
          label={t("label_book_widget_depart")}
          listData={dateArray}
          onChange={() => {}}
          isLoading={false}
          disableSearch={false}
          isReadOnly={true}
          customClassName={styles["flight-status-date-dropdown"]}
          defaultValue={value?.date}
          focused={{ pax: focused?.dateFocused }}
          onSelect={(e: any, selectedObj: any) =>
            handleFieldChange(selectedObj?.listName, "date")
          }
          offcanvasClass={styles["floating-select-bottomsheet"]}
          backdropClass={styles["floating-select-bottomsheet-backdrop"]}
        />
        <div
          className={`${styles["flight-status-route-button"]} flight-status-route-button`}
        >
          <TGButton
            label={t("button_flight_status")}
            variant="primary"
            buttonSize="sm"
            customClass="bookingButtonCheckIn"
            onClick={handleSearch}
            disabled={
              Object.keys(value.from).length === 0 ||
              Object.keys(value.to).length === 0 ||
              value.date === "" ||
              value.date === null
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FlightRoute;
