import React, { useEffect, useState } from "react";
import styles from "./flight-number.module.scss";
import { useTranslation } from "react-i18next";
import TGTextField from "../../../../../../shared/tg-text-field";
import TGButton from "../../../../../../shared/tg-button";
import TGFloatingSelect from "../../../../../../shared/tg-select";
import moment from "moment";
import { formatDate } from "../../../../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  flightStatusByNumberRequest,
  flightStatusNumberClear,
  flightStatusNumberInputSave,
} from "../../../../slice/flightStatusSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../../slice/RootReducer";
import { saveAccessedActiveTab } from "../../../../slice/bookingWidgetSlice";

interface Props {
  type?: string;
  currentTabIndex?: number;
  handleCloseSidebar?: any;
}

const FlightNumber: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const airportDetails = useSelector(
    (state: RootState) => state?.osciReducer?.bookingWidget.airportData
  );
  const flightNumberSaveData = useSelector(
    (state: RootState) => state?.osciReducer?.flightStatus.flightNumberInputs
  );

  const flightStatusSelectedData = useSelector(
    (state: RootState) =>
      state?.osciReducer?.flightStatus.selectedRecentSearchData
  );

  const [value, setValue] = useState<any>({
    flightNumber: null,
    date: moment(new Date()).format("ddd, DD MMM YYYY"),
  });
  const [focused, setFocused] = useState({
    flightNumber: false,
    date: false,
  });
  const [disableSearchButton, setDisableSearchButton] = useState<boolean>(true);

  const createDateArray = (startDate: any, range: number) => {
    const dates = [];
    for (let i = -range; i <= range; i++) {
      const newDate = moment(startDate).add(i, "days");
      dates.push({
        id: i + range + 1,
        listName: formatDate(newDate, true),
        value: formatDate(newDate, false),
      });
    }
    return dates;
  };
  const startDate = moment();
  const dateArray = createDateArray(startDate, 2);

  const handleSelectData = (e: any, selectedValue: any) => {
    setFocused({ flightNumber: false, date: false });
    setValue({ ...value, date: selectedValue.listName });
  };

  const handleFlightNumberChange = (e: any, label: any) => {
    const input = e.target.value.replace(/^TG\s*/, "");

    if (input.length <= 4) {
      setValue({ ...value, flightNumber: input });

      if (input.length === 0) {
        setDisableSearchButton(true);
      }
    }
  };

  const handleFlightStatusByNumber = () => {
    const inputData = { date: value?.date, flightNumber: value?.flightNumber };
    dispatch(flightStatusNumberClear());
    dispatch(flightStatusNumberInputSave(inputData));
    dispatch(
      flightStatusByNumberRequest({
        date: moment(value?.date).format("DDMMYY"),
        flightNumber: value?.flightNumber,
      })
    );
    props?.handleCloseSidebar && props?.handleCloseSidebar(false);
    dispatch(saveAccessedActiveTab([3, "Flight Number"]));
    const url = `/${i18n.language}/flightstatus/flightstatusdetail`;
    navigate(url, {
      state: airportDetails,
    });
  };

  const handleFlightNumberKeyDown = (e: any, label: string) => {
    const key = e.key;
    const input = e.target;
    const prefixLength = 4;

    const navigationKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "ArrowDown",
      "ArrowUp",
      "Tab",
      "Delete",
    ];

    if (input.selectionStart < prefixLength && navigationKeys.includes(key)) {
      e.preventDefault();
      input.setSelectionRange(prefixLength, prefixLength);
    }

    if (e.key === "Enter") {
      setFocused({ flightNumber: false, date: true });
    }

    if (navigationKeys.includes(key)) {
      return;
    }

    if (!/^\d$/.test(key)) {
      e.preventDefault();
      return;
    }
    if (
      e.key === "0" &&
      (value?.flightNumber === null || value?.flightNumber === "")
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (flightNumberSaveData !== null) {
      setValue(flightNumberSaveData);
    }
  }, [flightNumberSaveData]);

  useEffect(() => {
    if (
      flightStatusSelectedData !== null &&
      flightStatusSelectedData.type === "Flight Number"
    ) {
      let updatedValue = {
        flightNumber: flightStatusSelectedData.flightNumber,
        date: flightStatusSelectedData.date,
      };
      setValue(updatedValue);
    }
  }, [flightStatusSelectedData]);

  useEffect(() => {
    if (
      props.currentTabIndex === 3 ||
      location.pathname.toLowerCase() ===
        `/${i18n.language.toLowerCase()}/flightstatus`
    ) {
      setFocused({
        flightNumber: true,
        date: false,
      });
    }
  }, [props.currentTabIndex, i18n.language, location.pathname]);

  useEffect(() => {
    if (value?.flightNumber) {
      setDisableSearchButton(false);
    } else {
      setDisableSearchButton(true);
    }
  }, [value]);

  return (
    <>
      <p className={styles["flight-status-caption"]}>
        {t("label_flightstatusbyno_desc")}
      </p>
      <div className={styles["flight-status-container"]}>
        <div>
          <TGTextField
            label={t("label_book_widget_flight_number")}
            placeholderText={t("label_book_widget_tg")}
            type="text"
            variant={true}
            customInputClassName={styles["input-promotion-code"]}
            onTextChange={(e, label) => {
              handleFlightNumberChange(e, label);
            }}
            value={`TG ${value?.flightNumber || ""}`}
            maxlength={7}
            focus={focused.flightNumber}
            onKeyDown={(e, label) => {
              handleFlightNumberKeyDown(e, label);
            }}
            onMouseUp={(e: { target: HTMLInputElement }) => {
              const input = e.target;
              if (input.selectionStart !== null && input.selectionStart < 3) {
                input.setSelectionRange(3, 3);
              }
            }}
            id={"FlightNumber"}
          />
        </div>
        <div>
          <TGFloatingSelect
            label={t("label_book_widget_depart")}
            listData={dateArray}
            onChange={() => {}}
            isLoading={false}
            disableSearch={false}
            isReadOnly={true}
            defaultValue={value?.date}
            onSelect={handleSelectData}
            focused={{ pax: focused?.date }}
            offcanvasClass={styles["floating-select-bottomsheet"]}
            backdropClass={styles["floating-select-bottomsheet-backdrop"]}
          />
        </div>
        <div
          className={`${styles["flight-status-number-button"]} flight-status-number-button`}
        >
          <TGButton
            label={t("button_flight_status")}
            variant="primary"
            buttonSize="sm"
            customClass="bookingButtonCheckIn"
            onClick={handleFlightStatusByNumber}
            disabled={disableSearchButton}
          />
        </div>
      </div>
    </>
  );
};

export default FlightNumber;
