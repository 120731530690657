// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kSeUgq3bgGlTHblcEpad {
  color: var(--gray-text, #6A6D87);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
}

.q4p7OholRbgf7bgF7hDL {
  width: 100%;
}

.SxI_9DLY3Uc7YBFl6j4W {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-self: stretch;
  justify-content: center;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 767px) {
  .SxI_9DLY3Uc7YBFl6j4W {
    flex-direction: column;
    gap: 0.75rem;
  }
}
.SxI_9DLY3Uc7YBFl6j4W > div:first-child,
.SxI_9DLY3Uc7YBFl6j4W > div:nth-child(2) {
  flex: 1;
}
.SxI_9DLY3Uc7YBFl6j4W > div:first-child div > div > input {
  height: 64px;
}
.SxI_9DLY3Uc7YBFl6j4W > button {
  width: 180px;
  height: 64px;
}
@media screen and (max-width: 767px) {
  .SxI_9DLY3Uc7YBFl6j4W > button {
    width: 100%;
  }
}

.Gj78HjIa3hS_G2NYqddu {
  width: 20%;
  height: 64px;
}
@media screen and (max-width: 767px) {
  .Gj78HjIa3hS_G2NYqddu {
    width: 100%;
  }
}
.Gj78HjIa3hS_G2NYqddu > button {
  height: 100%;
}

.Q6R2QaQHYShKqb3fSP_r {
  z-index: 1046 !important;
}

.ps5VdYkuVpwSLE0Pa2NZ {
  z-index: 1047 !important;
}`, "",{"version":3,"sources":["webpack://./src/OSCI/src/component/booking-widget/flight-status/flight-number/flight-number.module.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EAOA,SAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;AALJ;AAHI;EAJJ;IAKQ,sBAAA;IACA,YAAA;EAMN;AACF;AACI;;EAEI,OAAA;AACR;AAGQ;EACI,YAAA;AADZ;AAKI;EACI,YAAA;EACA,YAAA;AAHR;AAKQ;EAJJ;IAKQ,WAAA;EAFV;AACF;;AAOA;EACI,UAAA;EAMA,YAAA;AATJ;AAKI;EAHJ;IAIQ,WAAA;EAFN;AACF;AAMI;EACI,YAAA;AAJR;;AAQA;EACI,wBAAA;AALJ;;AAQA;EACI,wBAAA;AALJ","sourcesContent":[".flight-status-caption {\n    color: var(--gray-text, #6A6D87);\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 160%;\n    margin-bottom: 1.5rem;\n    margin-top: 2rem;\n}\n\n.flight-number-container {\n    width: 100%;\n}\n\n.flight-status-container {\n    display: flex;\n    flex-direction: row;\n\n    @media screen and (max-width:767px) {\n        flex-direction: column;\n        gap: 0.75rem;\n    }\n\n    gap: 1rem;\n    align-self: stretch;\n    justify-content: center;\n    margin-bottom: 0.5rem;\n\n    >div:first-child,\n    >div:nth-child(2) {\n        flex: 1;\n    }\n\n    >div:first-child {\n        div>div>input {\n            height: 64px;\n        }\n    }\n\n    >button {\n        width: 180px;\n        height: 64px;\n\n        @media screen and (max-width:767px) {\n            width: 100%;\n        }\n    }\n}\n\n\n.flight-status-number-button {\n    width: 20%;\n\n    @media screen and (max-width: 767px) {\n        width: 100%;\n    }\n\n    height: 64px;\n\n    >button {\n        height: 100%;\n    }\n}\n\n.floating-select-bottomsheet-backdrop {\n    z-index: 1046 !important;\n}\n\n.floating-select-bottomsheet {\n    z-index: 1047 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flight-status-caption": `kSeUgq3bgGlTHblcEpad`,
	"flight-number-container": `q4p7OholRbgf7bgF7hDL`,
	"flight-status-container": `SxI_9DLY3Uc7YBFl6j4W`,
	"flight-status-number-button": `Gj78HjIa3hS_G2NYqddu`,
	"floating-select-bottomsheet-backdrop": `Q6R2QaQHYShKqb3fSP_r`,
	"floating-select-bottomsheet": `ps5VdYkuVpwSLE0Pa2NZ`
};
export default ___CSS_LOADER_EXPORT___;
